<template>
  <section ref="content">
    <a-spin :spinning="loading" tip="加载中" size="large">
      <section class="wrap">
        <div class="headerText">市级复核推荐（4月17日 - 4月30日）</div>
        <ZgyxSearchTablePagination
          ref="searchTablePagination"
          :formConfigs="[]"
          tableRowKey="projectResultId"
          :tableColumns="[
            { type: 'input', dataIndex: 'mySort', title: '序号', width: 40 },
            { type: 'input', dataIndex: ['requisition', 'orgName'], title: '申报单位名称', width: 100 },
            { type: 'input', dataIndex: ['requisition', 'orgPrincipal'], title: '单位负责人', width: 100 },
            { type: 'input', dataIndex: ['requisition', 'orgMobile'], title: '联系电话', width: 100 },
            { type: 'input', dataIndex: ['requisition', 'orgFax'], title: '传真', width: 100 },
            //{ type: 'custom', dataIndex: 'orgAddress', title: '详细地址', width: 200 },
            { type: 'input', dataIndex: 'submitApplicationTime', title: '社区提交时间', width: 120 },
            { type: 'input', dataIndex: 'selfEvaluateScore', title: '社区自评总分', width: 120 },
            { type: 'custom', dataIndex: 'countyHandleStatusText', title: '区县核准', width: 200 },
            { type: 'custom', dataIndex: 'cityEvaluateScore', title: '市级评分', width: 200 },
            { type: 'custom', dataIndex: 'action', title: '操作', width: 200, fixed: 'right', align: 'center' }
          ]"
          :requestFunction="requestFunction"
          :scrollConfigs="{ x: 1800 }"
          :showDefaultButton="false"
        >
          <template #orgAddress="{ record }">
            <div class="county-wrap">
              {{ record.requisition.orgAddress }}
            </div>
          </template>
          <template #countyHandleStatusText="{ record }">
            <div class="county-wrap">
              <div>状态：{{ record.countyHandleStatusText }}</div>
              <div>联系人：{{ record.countyEvaluateUserName }}</div>
              <div>联系电话：{{ record.countyEvaluateUserMobile }}</div>
              <div v-if="record.countyHandleStatus == 4">意见：{{ record.countyOpinion || '同意' }}</div>
            </div>
          </template>
          <template #cityEvaluateScore="{ record }">
            <div class="city-wrap">
              <div>审核状态：{{ record.cityHandleStatusText }}</div>
              <div>评分：{{ record.cityEvaluateScore }}</div>
              <div>评审意见：{{ record.cityOpinion }}</div>
            </div>
          </template>

          <template #formExtends>
            <a-button class="btn" type="primary" danger @click="handleDownloadAll" v-preReClick> 导出</a-button>
          </template>
          <template #action="{ record }">
            <a-button type="primary" class="btn" size="small" v-if="record.canHandleType == 1" @click="toDetail(record)"
              >市级评分</a-button
            >
            <a-button type="primary" class="btn" size="small" v-if="record.canHandleType == 2" @click="toDetail(record)"
              >市级评分修改</a-button
            >
            <a-button
              type="primary"
              class="btn"
              size="small"
              v-if="record.canHandleType == 3"
              @click="toDetailByLook(record)"
              >详情</a-button
            >
            <a-button size="small" class="btn" type="primary" danger @click="handleDownloadItem(record)" v-preReClick>
              导出详情</a-button
            >
          </template>
        </ZgyxSearchTablePagination>
      </section>
    </a-spin>
  </section>
</template>

<script>
import moment from 'moment';
import { request } from '@src/utils';
import { mapGetters, mapActions } from 'vuex';
import { message } from 'ant-design-vue';
import { PlusOutlined } from '@ant-design/icons-vue';
// apis
import {
  apiGetCmsSelectionResultCitySearch,
  apiGetCmsSelectionResultCityDownLoad,
  cityDetailDownLoad
} from '@src/apis';
// mixins
// utils
// stores
// configs
import { downloadFile2, downloadFile, isJSON } from '@src/utils';
// components
import { ZgyxSearchTablePagination } from '@src/components';

export default {
  name: 'UserManagementsComponent',
  components: { ZgyxSearchTablePagination, PlusOutlined },
  data() {
    return {
      activeUser: {}, // 当前激活的用户
      modalType: 'add', // add/edit
      loading: false
    };
  },
  computed: {
    ...mapGetters({
      userTypes: 'userType'
    }),
    requestFunction() {
      return apiGetCmsSelectionResultCitySearch;
    }
  },
  mounted() {
    const _this = this;
    _this.$nextTick(() => {});
  },
  methods: {
    ...mapActions(['setActiveMenu']),
    async handleDownloadAll() {
      try {
        const _this = this;
        if (_this.loading) {
          return false;
        }
        _this.loading = true;
        const queryPagination = _this.$refs.searchTablePagination.queryPagination;

        const query = _this.$refs.searchTablePagination.query;

        let params = Object.assign({}, queryPagination, query);

        const { status, data, headers } = await apiGetCmsSelectionResultCityDownLoad(params);

        if (status === 200) {
          let filename = '';
          try {
            if (headers && headers['content-disposition']) {
              filename = headers['content-disposition'].split('=')[1];
            } else {
              filename = '';
            }
          } catch (error) {
            filename = '';
          }

          const { type } = data;

          new Blob([data], { type }).text().then((result) => {
            if (isJSON(result)) {
              const { message: msg } = JSON.parse(result);
              message.error(msg);
              _this.loading = false;
            } else {
              downloadFile(
                data,
                type,
                filename ? decodeURIComponent(filename) : '市级清单' + moment(new Date()).format('YYYY-MM-DD'),
                () => {
                  message.success('导出成功');
                  _this.loading = false;
                }
              );
            }
          });
        } else {
          _this.loading = false;
          message.error('网络开小差了');
        }
      } catch (error) {
        console.error(error);
      }
    },
    /**
     * 市级评分
     */
    toDetail(record) {
      this.setActiveMenu({ path: '/cityExamineDetail', name: '', query: { id: record.projectResultId, type: 'edit' } });
    },
    toDetailByLook(record) {
      this.setActiveMenu({ path: '/cityExamineDetail', name: '', query: { id: record.projectResultId, type: 'look' } });
    },
    //下载详情
    async handleDownloadItem(record) {
      try {
        const _this = this;
        if (_this.loading) {
          return false;
        }
        _this.loading = true;

        const { status, data, headers } = await cityDetailDownLoad({ projectResultId: record.projectResultId });

        if (status === 200) {
          let filename = '';
          try {
            if (headers && headers['content-disposition']) {
              filename = headers['content-disposition'].split('=')[1];
            } else {
              filename = '';
            }
          } catch (error) {
            filename = '';
          }

          const { type } = data;

          new Blob([data], { type }).text().then((result) => {
            if (isJSON(result)) {
              const { message: msg } = JSON.parse(result);
              message.error(msg);
              _this.loading = false;
            } else {
              downloadFile(
                data,
                type,
                filename ? decodeURIComponent(filename) : moment(new Date()).format('YYYY-MM-DD'),
                () => {
                  message.success('导出成功');
                  _this.loading = false;
                }
              );
            }
          });
        } else {
          _this.loading = false;
          message.error('网络开小差了');
        }
      } catch (error) {
        console.error(error);
      }
    }
  }
};
/*
防止改回来
       { type: 'input', dataIndex: 'countyOpinion', title: '区县核准意见', width: 120 },
*/
</script>

<style lang="less" scoped>
@import './ExamineSearchComponent.less';
.county-wrap,
.city-wrap {
  width: 100%;
  height: auto;
  overflow-y: auto;
  max-height: 200px;
}
</style>
